import React from "react";
import { Link } from "gatsby"

import Page404Layout from "../layouts/page404"
import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import Main from "../components/main"
import Breadcrumbs from "../components/chrome/breadcrumbs"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const Page404 = () => {
    return (
        <Page404Layout>
            <PageHeader headerText="Once upon at time in a galaxy far far away" />

            <Main>
                <Breadcrumbs title="404" path='/' />
                <div>You've reached a black hole, please <Link to={'/'}>
                    click here to return to safety.
                </Link></div>
            </Main>

            <PageFooter />
        </Page404Layout >
    )
}

export default Page404