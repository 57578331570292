import React from "react"

const Layout404 = ({ children }) => (
  <>
    <div className="page page--404">
      {children}
    </div>
  </>
)

export default Layout404